import axios from 'axios'

import {
    IMessengerChat,
    IMessengerChannel,
    IChat,
    IChannel,
    IChannelMember,
    IHistoryMessage,
} from 'interfaces'
import { APP_ID, APP_VERSION, REQUEST_HEADER_NAME_APP_ID } from 'config/app'
import { API_URL, WSS_CHAT_TOKEN, MS_CHAT_BASE_URL } from 'config/api'
import { parseTpl } from 'utils/helpers'
import { AuthService } from 'services'

export type TChatMicroServiceError = {
    errorCode: number
    errorMsg: string
    stack: {}
}

export type TConversationListProps = {
    userId?: number
    searchText?: string
    onlyArchived?: boolean
    withUnreadMessagesCount?: boolean
}

export type TConversationListPaginatedProps = TConversationListProps & {
    page: number
    paginatedBy?: number
}

export type TConversationCountsNewProps = {
    userId?: number
}

export type TChannelProps = {
    id: string
}

export type TChannelMembersProps = {
    channelId: string
    withUsersInfo?: boolean
    withChannelsInfo?: boolean
    page?: number
    paginatedBy?: number
}

export type TAddChannelMemberProps = {
    channelId: string
    userId: number
    isAdmin: boolean
}

export type TDeleteChannelMemberProps = {
    channelId: string
    userId?: number
}

export type TUserBelongsConversationsProps = {
    userId?: number
    onlyActiveMemberships?: boolean
    onlyArchivedMemberships?: boolean
    withUsersInfo?: boolean
    searchText?: string
    page?: number
    paginatedBy?: number
}

export type TUserBelongsChannelsProps = {
    userId?: number
    onlyActiveMemberships?: boolean
    onlyArchivedMemberships?: boolean
    withMembersCount?: boolean
    searchText?: string
    page?: number
    paginatedBy?: number
}

export type TUserBelongsMessagesProps = {
    userId?: number
    onlyActiveMessages?: boolean
    withUsersInfo?: boolean
    withChannelsInfo?: boolean
    searchText?: string
    page?: number
    paginatedBy?: number
}

export type THistoryChatProps = {
    chatUserId: number
    userId?: number
    withUsersInfo?: boolean
    onlyActive?: boolean
}

export type THistoryChatPaginatedProps = THistoryChatProps & {
    page: number
    paginatedBy?: number
}

export type THistoryChannelProps = {
    channelId: string
    withChannelsInfo?: boolean
    withUsersInfo?: boolean
    page?: number
    paginatedBy?: number
}

export type THistoryChannelPaginatedProps = THistoryChannelProps & {
    page: number
    paginatedBy?: number
}

export type TCreateChannelProps = {
    title: string
    userId?: number
    storeId?: number
    description?: string
    image?: File
    isPublic?: boolean
}

export type TConversationListResponse = (IMessengerChat | IMessengerChannel)[]

export type TConversationListPaginatedResponse = {
    data: TConversationListResponse
    totalItems: number
    totalPages: number
    currentPage: number
    paginatedBy: number
}

export type TConversationCountsNewResponse = {
    chatsWithNewMsgsCount: number
    archivedChatsWithNewMsgsCount: number
}

export type TChannelResponse = IChannel

export type TChannelMembersResponse = IChannelMember[]

export type TAddChannelMemberResponse = IChannelMember

export type TDeleteChannelMemberResponse = any // TODO

export type TUserBelongsConversationsResponse = IChat[]

export type TUserBelongsChannelsResponse = IChannel[]

export type TUserBelongsMessagesResponse = IHistoryMessage[]

export type THistoryChatResponse = IHistoryMessage[]

export type THistoryChatPaginatedResponse = {
    data: THistoryChatResponse
    totalItems: number
    totalPages: number
    currentPage: number
    paginatedBy: number
}

export type THistoryChannelResponse = IHistoryMessage[]

export type THistoryChannelPaginatedResponse = {
    data: THistoryChannelResponse
    totalItems: number
    totalPages: number
    currentPage: number
    paginatedBy: number
}

export type TCreateChannelResponse = IChannel

const msRequestClient = axios.create({
    baseURL: MS_CHAT_BASE_URL,
    responseType: 'json',
    headers: {
        AccessToken: WSS_CHAT_TOKEN,
        [REQUEST_HEADER_NAME_APP_ID]: `${APP_ID}/${APP_VERSION}`,
    },
})

msRequestClient.interceptors.request.use((requestConfig) => {
    const config = { ...requestConfig }
    const { access_token: authAccessToken } = AuthService.getAuthData() || {}

    if (authAccessToken) {
        config.headers.AppToken = authAccessToken
    }

    return config
}, (err) => {
    return Promise.reject(err)
})

class ChatMicroService {
    static fetchConversationList({ userId, ...params }: TConversationListProps) {
        const url = userId ? `${API_URL.msChatConversationList}${userId}` : API_URL.msChatConversationList
        return msRequestClient<TConversationListResponse>(url, { params })
    }

    static fetchConversationListPaginated({ userId, ...params }: TConversationListPaginatedProps) {
        const url = userId ? `${API_URL.msChatConversationList}${userId}` : API_URL.msChatConversationList
        return msRequestClient<TConversationListPaginatedResponse>(url, { params })
    }

    static fetchConversationCountsNew({ userId }: TConversationCountsNewProps) {
        const url = userId ? `${API_URL.msChatConversationCountsNew}${userId}` : API_URL.msChatConversationCountsNew
        return msRequestClient<TConversationCountsNewResponse>(url)
    }

    static fetchChannel({ id }: TChannelProps) {
        const url = parseTpl(API_URL.msChatChannel, { id })
        return msRequestClient<TChannelResponse>(url)
    }

    static createChannel(params: TCreateChannelProps) {
        const formData = new FormData()

        Object.entries(params).forEach(([key, value]) => {
            if (typeof value === 'number') {
                formData.append(key, String(value))
            } else if (typeof value === 'boolean') {
                formData.append(key, String(Number(value)))
            } else {
                formData.append(key, value)
            }
        })

        return msRequestClient<TCreateChannelResponse>(API_URL.msChatChannelCreate, { method: 'post', data: formData })
    }

    static fetchChannelMembers({ channelId, ...params }: TChannelMembersProps) {
        const url = parseTpl(API_URL.msChatChannelMembers, { channelId })
        return msRequestClient<TChannelMembersResponse>(url, { params })
    }

    static addChannelMember(params: TAddChannelMemberProps) {
        return msRequestClient<TAddChannelMemberResponse>(API_URL.msChatChannelMembersAdd, { method: 'post', data: params })
    }

    static deleteChannelMember({ channelId, userId }: TDeleteChannelMemberProps) {
        const url = parseTpl(API_URL.msChatChannelMembersDelete, { channelId })
        return msRequestClient<TDeleteChannelMemberResponse>(userId ? `${url}/${userId}` : url, { method: 'delete' })
    }

    static fetchUserBelongsConversations({ userId, ...params }: TUserBelongsConversationsProps) {
        const url = userId ? `${API_URL.msChatUserBelongsConversations}${userId}` : API_URL.msChatUserBelongsConversations
        return msRequestClient<TUserBelongsConversationsResponse>(url, { params })
    }

    static fetchUserBelongsChannels({ userId, ...params }: TUserBelongsChannelsProps) {
        const url = userId ? `${API_URL.msChatUserBelongsChannels}${userId}` : API_URL.msChatUserBelongsChannels
        return msRequestClient<TUserBelongsChannelsResponse>(url, { params })
    }

    static fetchUserBelongsMessages({ userId, ...params }: TUserBelongsMessagesProps) {
        const url = userId ? `${API_URL.msChatUserBelongsMessages}${userId}` : API_URL.msChatUserBelongsMessages
        return msRequestClient<TUserBelongsMessagesResponse>(url, { params })
    }

    static fetchHistoryChat({ chatUserId, userId, ...params }: THistoryChatProps) {
        const url = parseTpl(API_URL.msChatHistoryChat, { chatUserId })
        return msRequestClient<THistoryChatResponse>(userId ? `${url}/${userId}` : url, { params })
    }

    static fetchHistoryChatPaginated({ chatUserId, userId, ...params }: THistoryChatPaginatedProps) {
        const url = parseTpl(API_URL.msChatHistoryChat, { chatUserId })
        return msRequestClient<THistoryChatPaginatedResponse>(userId ? `${url}/${userId}` : url, { params })
    }

    static fetchHistoryChannel({ channelId, ...params }: THistoryChannelProps) {
        const url = parseTpl(API_URL.msChatHistoryChannel, { channelId })
        return msRequestClient<THistoryChannelResponse>(url, { params })
    }

    static fetchHistoryChannelPaginated({ channelId, ...params }: THistoryChannelPaginatedProps) {
        const url = parseTpl(API_URL.msChatHistoryChannel, { channelId })
        return msRequestClient<THistoryChannelPaginatedResponse>(url, { params })
    }
}

export default ChatMicroService
