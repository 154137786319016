import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
    IStoreCatalog,
    IStoreFolder,
    IStoreCurrency,
    IBasketWallet,
    IGoodsData,
} from 'interfaces'
import { TBasketUpdateProps } from 'services/MarketService'
import { TStoreCatalog } from 'services/StoreService'
import { CardSize } from 'enums'
import { APP_URL, PRODUCT_TYPE_PROMOTION_DESCRIPTION, BREAKPOINTS } from 'config/app'
import {
    useFetchStoreProfile,
    useFetchStoreRootCatalog,
    useFetchStoreCatalog,
    useFetchStorePopularGoods,
} from 'containers/Store/hooks'
import { useFetchBasket, useMutationBasket } from 'containers/Market/hooks'
import { useBasketGoods } from 'containers/StoreBasket/hooks'
import {
    Modal,
    Button,
    Loader,
    ErrorMsg,
    Iframe,
} from 'components'
import { showAlertNotify, getStoreCurrency, parseTpl } from 'utils/helpers'
import { MarketService } from 'services'
import { useWindowResize } from 'hooks'
import { CatalogItems } from './components'
import style from './Catalog.module.css'

type CatalogPropType = {
    isSetItemLink: boolean
    isPopularGoods?: boolean
    isShowBuyButton?: boolean
    storeId: number
    catalogId?: number
    onClickFolder?: (data: IStoreFolder) => void
    onClickGoods?: (data: IGoodsData) => void
    onEmptyCatalog?: () => void
}

const CATALOG_DEFAULT: IStoreCatalog = {
    headers: [],
    folders: [],
    goods: [],
}

const prepareCatalog = (data: TStoreCatalog): IStoreCatalog | undefined => {
    if (!data.length) {
        return undefined
    }
    return data.reduce((acc, item) => {
        const storeCatalog = { ...acc }

        if (item.type === 'header') {
            return { ...storeCatalog, headers: [...storeCatalog.headers, item] }
        }
        if (item.type === 'folder') {
            return { ...storeCatalog, folders: [...storeCatalog.folders, item] }
        }
        if (item.type === 'goods') {
            return { ...storeCatalog, goods: [...storeCatalog.goods, item] }
        }

        return storeCatalog
    }, CATALOG_DEFAULT)
}

const Catalog: React.FC<CatalogPropType> = ({
    isSetItemLink,
    isPopularGoods,
    isShowBuyButton,
    storeId,
    catalogId,
    onClickFolder,
    onClickGoods,
    onEmptyCatalog = () => {},
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const [goodsIframe, setGoodsIframe] = useState<IGoodsData>()
    const [catalogError, setCatalogError] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isOpenGoodsModalIframe, setIsOpenGoodsModalIframe] = useState(false)

    const { data: dataStoreProfile } = useFetchStoreProfile({ id: storeId }, { enabled: !Number.isNaN(storeId) })

    const {
        isInitialLoading: isLoadingStoreRootCatalog,
        data: dataStoreRootCatalog,
        error: errorStoreRootCatalog,
    } = useFetchStoreRootCatalog({
        storeId,
    }, {
        enabled: !isPopularGoods && !catalogId,
    })

    const {
        isInitialLoading: isLoadingStoreCatalog,
        data: dataStoreCatalog,
        error: errorStoreCatalog,
    } = useFetchStoreCatalog({
        storeId,
        catalogId: catalogId ?? 0,
    }, {
        enabled: !isPopularGoods && !!catalogId,
    })

    const {
        isInitialLoading: isLoadingStorePopularGoods,
        data: dataStorePopularGoods,
    } = useFetchStorePopularGoods({ storeId }, { enabled: !!isPopularGoods })

    const { data: dataBasket } = useFetchBasket({ storeId }, { enabled: !!dataStoreProfile })

    const { goodsInBasketDefaultPrice } = useBasketGoods({ basket: dataBasket, goodsId: goodsIframe?.id })

    const { update: updateBasket } = useMutationBasket()

    const isLoading = useMemo(() => {
        return (isLoadingStoreRootCatalog && !dataStoreRootCatalog)
            || (isLoadingStoreCatalog && !dataStoreCatalog)
            || isLoadingStorePopularGoods
    }, [
        isLoadingStoreRootCatalog,
        isLoadingStoreCatalog,
        isLoadingStorePopularGoods,
        dataStoreRootCatalog,
        dataStoreCatalog,
    ])

    const catalog: IStoreCatalog | undefined = useMemo(() => {
        if (isPopularGoods && dataStorePopularGoods) {
            return {
                headers: [],
                folders: [],
                goods: dataStorePopularGoods.map((item) => ({ type: 'goods', sort: item.sort, container: item })),
            }
        }
        if (!catalogId && dataStoreRootCatalog) {
            return prepareCatalog(dataStoreRootCatalog)
        }
        if (catalogId && dataStoreCatalog) {
            return prepareCatalog(dataStoreCatalog)
        }

        return undefined
    }, [
        catalogId,
        dataStorePopularGoods,
        dataStoreRootCatalog,
        dataStoreCatalog,
    ])

    const currency: IStoreCurrency | undefined = useMemo(() => {
        return dataStoreProfile ? getStoreCurrency(dataStoreProfile) : undefined
    }, [dataStoreProfile])

    const payoutCurrency = useMemo(() => {
        return MarketService.getPayoutCurrency(dataBasket?.market_wallet_list)
    }, [dataBasket])

    const wallets: IBasketWallet[] = useMemo(() => dataBasket?.market_wallet_list || [], [dataBasket])

    const itemsSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerCloseModalGoodsIframe = () => {
        setGoodsIframe(undefined)
        setIsOpenGoodsModalIframe(false)
    }

    const handlerClickGoods = (data: IGoodsData) => {
        if (isSetItemLink && data.landing_link) {
            setGoodsIframe(data)
            setIsOpenGoodsModalIframe(true)
        } else if (onClickGoods) {
            onClickGoods(data)
        }
    }

    const handlerAddGoodsToBasket = ({ id }: IGoodsData, count: number) => {
        addToBasketAction(id, count)
    }

    const handlerAddGoodsToBasketCV = ({ id }: IGoodsData, count: number) => {
        addToBasketAction(id, count, MarketService.getPayoutCurrency(wallets))
    }

    const handlerAddLandingGoodsToBasket = () => {
        if (goodsInBasketDefaultPrice?.quantity) {
            history.push(parseTpl(APP_URL.basket, { ':id': storeId }, { prefix: '', suffix: '' }))
        } else if (goodsIframe) {
            addToBasketAction(goodsIframe.id, 1)
        }
    }

    function handlerErrorAddToBasket(message?: string) {
        showAlertNotify({ type: 'error', message: message || t('update_error') })
    }

    function addToBasketAction(goodsId: number, count: number, paymentCurrency: string = '') {
        const params: TBasketUpdateProps = {
            storeId: Number(storeId),
            goods: goodsId,
            quantity: count,
        }

        if (paymentCurrency) {
            params.payment_by = paymentCurrency
        }

        setIsSubmitting(true)
        updateBasket.mutate(params, {
            onSuccess: (data) => {
                if (data?.warning_list?.length) {
                    handlerErrorAddToBasket(data.warning_list[0]?.description)
                }
            },
            onError: () => {
                handlerErrorAddToBasket()
            },
            onSettled: () => {
                setIsSubmitting(false)
                setIsOpenGoodsModalIframe(false)
            },
        })
    }

    useEffect(() => {
        if (!isPopularGoods && !catalogId && dataStoreRootCatalog && !dataStoreRootCatalog.length) {
            onEmptyCatalog()
        }
    }, [dataStoreRootCatalog])

    useEffect(() => {
        if (errorStoreRootCatalog) {
            setCatalogError(errorStoreRootCatalog)
        }
        if (errorStoreCatalog) {
            setCatalogError(errorStoreCatalog)
        }
    }, [errorStoreRootCatalog, errorStoreCatalog])

    return (
        <>
            {isLoading && (
                <Loader />
            )}

            {!isLoading && catalogError && (
                <ErrorMsg error={catalogError} />
            )}

            {!isLoading && !catalogError && !catalog && !isPopularGoods && (
                <ErrorMsg error={t('nothing_found')} />
            )}

            {!isLoading && catalog && (
                <div className={style.catalog}>
                    <CatalogItems
                        isSetItemLink={isSetItemLink}
                        isShowBuyButton={isShowBuyButton}
                        storeId={storeId}
                        catalog={catalog}
                        basket={dataBasket}
                        itemsSize={itemsSize}
                        storeCurrency={currency}
                        payoutCurrency={payoutCurrency}
                        onClickFolder={onClickFolder}
                        onClickGoods={handlerClickGoods}
                        onAddGoodsToBasket={handlerAddGoodsToBasket}
                        onAddGoodsToBasketCV={handlerAddGoodsToBasketCV}
                    />
                </div>
            )}

            <Modal
                isOpen={isOpenGoodsModalIframe}
                classes={style.modalIframe}
                classesOverlay={style.modalIframeOverlay}
                size="large"
                onClose={handlerCloseModalGoodsIframe}
            >
                <Modal.Header isCloseButton>
                    {goodsIframe?.name}
                </Modal.Header>
                <Modal.Body classes={style.modalBodyIframe}>
                    {goodsIframe?.landing_link ? (
                        <Iframe
                            title={goodsIframe.name}
                            src={goodsIframe.landing_link}
                        />
                    ) : (
                        <ErrorMsg error={t('update_error')} />
                    )}
                </Modal.Body>
                {goodsIframe?.product_type?.id !== PRODUCT_TYPE_PROMOTION_DESCRIPTION && (
                    <Modal.Footer>
                        <Button
                            size="size44"
                            text={goodsInBasketDefaultPrice?.quantity ? t('market_in_basket') : t('Add to cart')}
                            disabled={isSubmitting}
                            onClick={handlerAddLandingGoodsToBasket}
                        />
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}

export default Catalog
