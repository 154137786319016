import React, { useEffect, useState } from 'react'

import { IMessengerChat, IMessengerChannel, IUser } from 'interfaces'
import { TConversationListPaginatedResponse } from 'services/ChatMicroService'
import { useFetchInfiniteConversationList } from 'containers/Messenger/hooks'
import { InfiniteScroll } from 'components'

type MessengerConversationListPropType = {
    user: IUser
    limit?: number
    render: (data: IMessengerChat | IMessengerChannel) => void
    onLoad?: (res: TConversationListPaginatedResponse) => void
}

const MessengerConversationList: React.FC<MessengerConversationListPropType> = ({
    user,
    limit = 20,
    render,
    onLoad = () => {},
}) => {
    const [isHideLoaderConversationList, setIsHideLoaderConversationList] = useState(false)

    const {
        isFetching: isFetchingConversationList,
        data: dataConversationList,
        fetchNextPage: fetchNextPageConversationList,
    } = useFetchInfiniteConversationList({
        userId: user.id,
        page: 1,
        paginatedBy: limit,
    }, {
        enabled: !!user,
        staleTime: 60 * 1000, // 1m
    })

    const handlerLoadConversationList = () => {
        if (!isFetchingConversationList) {
            fetchNextPageConversationList()
        }
    }

    useEffect(() => {
        if (dataConversationList) {
            const { pages } = dataConversationList
            const pagesLen = pages.length
            const lastListLen = pagesLen ? pages[pagesLen - 1].data.length : 0

            if (pagesLen) {
                onLoad(dataConversationList.pages[pagesLen - 1])
            }
            if (lastListLen < limit) {
                setIsHideLoaderConversationList(true)
            }
        }
    }, [dataConversationList])

    return (
        <InfiniteScroll
            isHideLoader={isHideLoaderConversationList}
            onLoad={handlerLoadConversationList}
        >
            {dataConversationList?.pages.map((page) => (
                <React.Fragment key={page.currentPage}>
                    {page.data.map((item) => (
                        <React.Fragment key={item.id}>
                            {render(item)}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
        </InfiniteScroll>
    )
}

export default MessengerConversationList
