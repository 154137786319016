import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IUser, IPhoneData } from 'interfaces'
import { PhoneDataFields } from 'enums'
import { ErrorMsg } from 'components'
import { fetchUser } from 'containers/User/user-actions'
import { AuthPhoneAction } from 'form-actions'
import { AuthService, UserService, TranslationService } from 'services'
import { useThunkDispatch } from 'hooks'
import {
    showAlertNotify,
    getRequestError,
    splitStr,
} from 'utils/helpers'

import styleForm from 'styles/modules/form.module.css'
import style from './Auth.module.css'

const Auth: React.FC = () => {
    const isMounted = useRef(false)
    const { t, i18n } = useTranslation()
    const thunkDispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()

    const [authError, setAuthError] = useState('')
    const [isDisabledAuthPhoneSubmit, setIsDisabledAuthPhoneSubmit] = useState<boolean>()

    const textPhoneSendInfo = useMemo(() => {
        return splitStr(t('phone_send_info'))
            .map((item) => item.text)
            .join(' ')
    }, [i18n.language])

    const handlerAuthPhoneSuccess = (data: IPhoneData) => {
        fetchUserAction(data)
    }

    const handlerAuthSmsCodeSuccess = () => {
        fetchUserAction()
    }

    const handlerAuthError = (errorMessage: string) => {
        setAuthError(errorMessage)
    }

    const handlerEndSmsTimer = () => {
        setIsDisabledAuthPhoneSubmit(false)
    }

    const handlerCallAuth = (data: IPhoneData) => {
        const { [PhoneDataFields.countryCode]: code, [PhoneDataFields.phone]: phone } = data
        const matchNumbers = `${code}${phone}`.match(/\d+/g)

        if (matchNumbers) {
            setIsDisabledAuthPhoneSubmit(true)
            getAuthByCallAction(matchNumbers.join(''))
        }
    }

    function authorize(user: IUser) {
        authorizeAction(user)
            .then((route) => {
                if (route) {
                    history.push(route)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: getRequestError(err) || t('update_error') })
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsDisabledAuthPhoneSubmit(false)
                }
            })
    }

    function fetchUserAction(userPhoneData?: IPhoneData) {
        thunkDispatch(fetchUser())
            .then((user) => {
                if (user.language.lang_short !== i18n.language) {
                    TranslationService.changeLanguage(user.language.lang_short)
                }
                authorize(user)
            })
            .catch((err) => {
                const { error, error_description: errorDesc } = err?.response?.data || {}
                let errorText = t('update_error')

                if (error === 'invalid_grant') {
                    errorText = errorDesc
                }
                if (userPhoneData) {
                    UserService.removeAuthData({
                        countryId: Number(userPhoneData[PhoneDataFields.countryId]),
                        phone: userPhoneData[PhoneDataFields.phone],
                    })
                }

                setIsDisabledAuthPhoneSubmit(false)
                showAlertNotify({ type: 'error', message: errorText })
            })
    }

    function getAuthByCallAction(phone: string) {
        AuthService.getCallAuth(phone)
            .then(() => {
                //
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: getRequestError(err) || t('update_error') })
            })
    }

    /**
     * deprecated: auth by call phone sessia
     */
    // function getCallAuthAction(phone: string) {
    //     AuthService.getCallAuth(phone)
    //         .then(({ data }) => {
    //             const { check_id, call_phone_pretty } = data
    //
    //             if (check_id && call_phone_pretty) {
    //                 setCallAuthPhone(call_phone_pretty)
    //
    //                 if (phoneData) {
    //                     // start check requests after time
    //                     setTimeout(() => {
    //                         checkCallAuthPhoneAction({
    //                             check_id,
    //                             country_id: Number(phoneData[PhoneDataFields.countryId]),
    //                             country_code: phoneData[PhoneDataFields.countryCode],
    //                             phone_number: phoneData[PhoneDataFields.phone],
    //                         })
    //                     }, 10000)
    //                 }
    //             } else {
    //                 setIsShowAuthCall(false)
    //                 setAuthError(t('update_error'))
    //             }
    //         })
    //         .catch((err) => {
    //             const errorText = err?.response?.data?.error?.text
    //
    //             setIsShowAuthCall(false)
    //             setAuthError(errorText || t('update_error'))
    //         })
    // }

    // function checkCallAuthPhoneAction(params: confirmCallAuthPropType) {
    //     authService.confirmCallAuth(params)
    //         .then(() => {
    //             fetchUserAction()
    //         })
    //         .catch((err) => {
    //             const { error_description: errorDesc } = err?.response?.data || {}
    //             setAuthError(errorDesc || t('update_error'))
    //         })
    // }

    function authorizeAction(user: IUser) {
        return AuthService.authorize(user, location)
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <div className={style.auth}>
            <div className={style.info}>
                {textPhoneSendInfo}
            </div>
            <AuthPhoneAction
                classes={style.form}
                isDisabledSubmit={isDisabledAuthPhoneSubmit}
                onError={handlerAuthError}
                onEndSmsTimer={handlerEndSmsTimer}
                onCallAuth={handlerCallAuth}
                onSuccessPhone={handlerAuthPhoneSuccess}
                onSuccessCode={handlerAuthSmsCodeSuccess}
            />

            {authError && (
                <ErrorMsg
                    classes={cn(style.error, styleForm.dangerText)}
                    error={authError}
                />
            )}
        </div>
    )
}

export default Auth
