import React, { useEffect, useState } from 'react'

import { IStoreProfile, IStoreFolder, IGoodsData } from 'interfaces'
import { CardContainer } from 'layout'
import { InfiniteScroll } from 'components'
import { Catalog } from 'containers'
import { useFetchInfiniteStores } from 'containers/Market/hooks'
import { CatalogProductSelectorStore } from './components'

type CatalogProductSelectorPropType = {
    classes?: string
    onSelectGoods: (data: IGoodsData) => void
}

const LIMIT = 12
const OFFSET = 0

const CatalogProductSelector: React.FC<CatalogProductSelectorPropType> = ({ classes, onSelectGoods }) => {
    const [storeId, setStoreId] = useState<number | undefined>()
    const [catalogId, setCatalogId] = useState<number | undefined>()
    const [isHideLoaderStores, setIsHideLoaderStores] = useState(false)

    const {
        isLoading: isLoadingStores,
        data: dataStores,
        // error,
        fetchNextPage: fetchNextPageStores,
    } = useFetchInfiniteStores({ limit: LIMIT, offset: OFFSET })

    const handlerLoadStores = () => {
        if (!isLoadingStores) {
            fetchNextPageStores()
        }
    }

    const handlerSelectStore = ({ id }: IStoreProfile) => {
        if (id) {
            setStoreId(id)
        }
    }

    const handlerClickCatalogFolder = ({ id }: IStoreFolder) => {
        if (id) {
            setCatalogId(id)
        }
    }

    useEffect(() => {
        if (dataStores) {
            const { pages } = dataStores
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < LIMIT) {
                setIsHideLoaderStores(true)
            }
        }
    }, [dataStores])

    return (
        <div className={classes}>
            {storeId ? (
                <>
                    {/* TODO */}
                    {/* <StoreBreadcrumbs storeId={storeId} catalogId={catalogId} /> */}

                    <Catalog
                        isSetItemLink={false}
                        isShowBuyButton={false}
                        storeId={storeId}
                        catalogId={catalogId}
                        onClickFolder={handlerClickCatalogFolder}
                        onClickGoods={onSelectGoods}
                    />
                </>
            ) : (
                <InfiniteScroll isHideLoader={isHideLoaderStores} onLoad={handlerLoadStores}>
                    <CardContainer>
                        {dataStores?.pages.map((page, i) => (
                            // eslint-disable-next-line
                            <React.Fragment key={i}>
                                {page.map((item) => (
                                    <CatalogProductSelectorStore
                                        data={item}
                                        key={item.id}
                                        onClick={handlerSelectStore}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </CardContainer>
                </InfiniteScroll>
            )}
        </div>
    )
}

export default CatalogProductSelector
