import React from 'react'
import cn from 'classnames'

import style from './Radio.module.css'

type RadioPropType = {
    name: string
    classes?: string
    classesText?: string
    text?: string
    value?: string
    checked?: boolean
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Radio: React.FC<RadioPropType> = ({
    name,
    classes,
    classesText,
    text,
    value = '',
    checked,
    disabled,
    onChange = () => {},
}) => {
    return (
        <label className={cn(style.label, classes)}>
            <span className={style.inner}>
                <input
                    className={style.radio}
                    type="radio"
                    name={name}
                    value={value}
                    checked={!!checked}
                    disabled={!!disabled}
                    onChange={onChange}
                />
                <span className={style.circle} />
                {text && (
                    <span className={cn(style.text, classesText)}>
                        {text}
                    </span>
                )}
            </span>
        </label>
    )
}

export default Radio
