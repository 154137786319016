import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    useQuery,
    useInfiniteQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IStoreProfile } from 'interfaces'
import { TStoresProps } from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TStoresProps
export type TError = string
export type TQueryKey = [string, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<IStoreProfile[], TError, IStoreProfile[], TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    IStoreProfile[],
    TError,
    IStoreProfile[],
    IStoreProfile[],
    TQueryKey
>

const key = QueryCacheKeys.storesProfiles

/**
 * Хук API
 */
export default function useFetchStores(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchStores(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}

export function useFetchInfiniteStores(initParams: TFetchFnParams = {}, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return MarketService.fetchStores(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        cacheTime,
        staleTime,
        ...opts,
    })
}
