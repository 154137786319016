import {
    UseQueryOptions,
    // UseInfiniteQueryOptions,
    // InvalidateQueryFilters,
    // InvalidateOptions,
    // useQueryClient,
    useQuery,
    // useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IGoods } from 'interfaces'
import { TGoodsProps } from 'services/GoodsService'
import { GoodsService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TGoodsProps
export type TError = string
export type TQueryKey = [string, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<IGoods[], TError, IGoods[], TQueryKey>

const key = 'goods'

/**
 * Хук API получить товар
 */
export default function useFetchGoods(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return GoodsService.fetchGoods(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
