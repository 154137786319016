import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { DatePeriod } from 'enums'
import { Button, DateChanger } from 'components'
import style from './CalendarHeader.module.css'

type CalendarHeaderPropType = {
    classes?: string
    period?: DatePeriod
    date?: Date
    onChangePeriod: (period: DatePeriod) => void
    onChangeDate: (date: Date) => void
    onAddEvent: () => void
}

const CalendarHeader: React.FC<CalendarHeaderPropType> = ({
    classes,
    period,
    date,
    onChangePeriod,
    onChangeDate,
    onAddEvent,
}) => {
    const { t } = useTranslation()

    const handlerClickDay = () => {
        onChangePeriod(DatePeriod.day)
    }

    const handlerClickMonth = () => {
        onChangePeriod(DatePeriod.month)
    }

    const handlerClickYear = () => {
        onChangePeriod(DatePeriod.year)
    }

    return (
        <div className={cn(style.header, classes)}>
            <div className={cn(
                style.item,
                style.item_order2,
                style.item_fullWidth,
                style.item_pLeft,
                style.item_m_center,
            )}
            >
                {period && (
                    <>
                        <Button
                            classes={cn(
                                style.periodsAction,
                                { [style.periodsAction_active]: period === DatePeriod.day },
                            )}
                            styleType="bordered2"
                            size="size32"
                            onClick={handlerClickDay}
                        >
                            {t('calendar_period_day')}
                        </Button>
                        <Button
                            classes={cn(
                                style.periodsAction,
                                { [style.periodsAction_active]: period === DatePeriod.month },
                            )}
                            styleType="bordered2"
                            size="size32"
                            onClick={handlerClickMonth}
                        >
                            {t('calendar_period_month')}
                        </Button>
                        <Button
                            classes={cn(
                                style.periodsAction,
                                { [style.periodsAction_active]: period === DatePeriod.year },
                            )}
                            styleType="bordered2"
                            size="size32"
                            onClick={handlerClickYear}
                        >
                            {t('calendar_period_year')}
                        </Button>
                    </>
                )}
            </div>
            <div className={cn(style.item, style.item_order3, style.item_pLeft, style.item_m_center)}>
                <Button
                    disabled // FIXME after release KW-139
                    textUpper
                    classes={style.action}
                    styleType="bordered"
                    size="size32"
                    onClick={onAddEvent}
                >
                    {t('ui_posts_create_event_title')}
                    <span className={style.actionPlus}>
                        +
                    </span>
                </Button>
            </div>
            {period && date && (
                <DateChanger
                    classes={cn(style.item, style.item_order1, style.item_fullWidth)}
                    period={period}
                    date={date}
                    onChange={onChangeDate}
                />
            )}
        </div>
    )
}

export default CalendarHeader
