import {
    IGoods,
} from 'interfaces'
import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'

export type TGoodsProps = {
    id: number
}

class GoodsService {
    static fetchGoods({ id }: TGoodsProps) {
        return requestClient<IGoods[]>(`${API_URL.goods}/${id}`)
    }
}

export default GoodsService
