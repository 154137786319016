import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IMenuNavItemData } from 'interfaces'
import { Badge, SvgResource } from 'components/index'
import style from './MenuNavContent.module.css'

export enum NavLinkContent {
    parent = 'parent',
    parentWithChildren = 'parentWithChildren',
    child = 'child',
}

type MenuNavLinkContentPropType = {
    data: IMenuNavItemData
    type: NavLinkContent
    isItemOpen?: boolean
    isActive?: boolean
}

const MenuNavContent: React.FC<MenuNavLinkContentPropType> = ({
    data,
    type,
    isItemOpen,
    isActive,
}) => {
    const {
        icon,
        iconClassName,
        translateKey,
        countValue,
        countEvent,
        disabled,
    } = data

    const { t } = useTranslation()

    return (
        <div className={cn(style.placeholder, { [style.placeholderHover]: !disabled })}>
            <div className={style.inner}>
                {(type === NavLinkContent.parent || type === NavLinkContent.parentWithChildren) && (
                    <>
                        <span className={cn(style.icon, { [style.link_disabled]: disabled })}>
                            {icon && (
                                <SvgResource
                                    isImgTag={false}
                                    classes={iconClassName}
                                    resourceKey={icon}
                                    width={30}
                                    height={30}
                                />
                            )}
                        </span>
                        {translateKey && (
                            <span className={cn(
                                style.text,
                                { [style.text_active]: isActive, [style.link_disabled]: disabled },
                            )}
                            >
                                {t(translateKey)}
                            </span>
                        )}
                        {type === NavLinkContent.parentWithChildren && (
                            <div className={cn(style.iconDropDown, { [style.iconUp]: isItemOpen })} />
                        )}
                    </>
                )}
                {type === NavLinkContent.child && (
                    <div className={style.textChildPlaceholder}>
                        {translateKey && (
                            <span className={cn(
                                style.textChild,
                                { [style.text_active]: isActive, [style.link_disabled]: disabled },
                            )}
                            >
                                {t(translateKey)}
                            </span>
                        )}
                    </div>
                )}
            </div>
            <div className={style.inner}>
                {!!countValue?.value && (
                    <span className={style.count}>
                        {countValue.value}
                    </span>
                )}
                {!!countEvent?.value && (
                    <Badge classes={style.badge}>
                        {countEvent.value}
                    </Badge>
                )}
            </div>
        </div>
    )
}

export default MenuNavContent
