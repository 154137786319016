import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IStoreProfile } from 'interfaces'
import { APP_URL } from 'config/app'
import { Catalog } from 'containers'
import { parseTpl } from 'utils/helpers'

import styleButton from 'components/Button/Button.module.css'
import style from './StoreProfileContent.module.css'

type StoreProfileContentPropType = {
    profile?: IStoreProfile
}

const StoreProfileContent: React.FC<StoreProfileContentPropType> = ({ profile }) => {
    const { t } = useTranslation()

    const catalogUrl = useMemo(() => {
        return profile ? parseTpl(APP_URL.store, { ':id': profile.id }, { prefix: '', suffix: '' }) : ''
    }, [profile])

    return (
        <>
            {profile && (
                <>
                    <h2 className={style.title}>
                        {t('search_popular')}
                    </h2>

                    <Catalog
                        isPopularGoods
                        isSetItemLink={false}
                        storeId={profile.id}
                    />
                </>
            )}

            {catalogUrl && (
                <div className={style.controls}>
                    <NavLink
                        className={cn(
                            styleButton.default,
                            styleButton.size44,
                        )}
                        to={catalogUrl}
                    >
                        {t('button_store_catalogue')}
                    </NavLink>
                </div>
            )}
        </>
    )
}

export default StoreProfileContent
