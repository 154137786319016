import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import {
    IMessengerChat,
    IMessengerChannel,
    IMessengerWsConversationStatusMessageProps,
} from 'interfaces'
import { TEventMessage } from 'form-actions/MessageConversationAction'
import { MessengerMessageStatus, MessengerMessageType } from 'enums'
import { APP_URL } from 'config/app'
import { ContentContainer, Block } from 'layout'
import {
    Loader,
    Button,
    ErrorMsg,
    NoDataInfo,
} from 'components'
import * as userSelectors from 'containers/User/user-selectors'
import { useFetchFriends } from 'containers/User/hooks'
import { MessageConversationAction } from 'form-actions'
import {
    MessengerMenu,
    MessengerSearch,
    MessengerSearchList,
    MessengerConversationChat,
    MessengerConversationChannel,
} from '../components'
import {
    useFetchConversationList,
    useMessenger,
    useMessengerChatUsersData,
    useInvalidateConversationCountsNew,
} from '../hooks'
import style from './MessengerConversationList.module.css'

type TConversationStatusParams = IMessengerWsConversationStatusMessageProps

const MessengerConversationList: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const debouncedInvalidate = useDebouncedCallback(invalidateAction, 500)

    const user = useSelector(userSelectors.user)

    const [searchText, setSearchText] = useState('')
    const [chatUserIds, setChatUserIds] = useState<number[]>([])
    const [conversationStatusParams, setConversationStatusParams] = useState<TConversationStatusParams>()

    const {
        isInitialLoading: isLoadingConversationList,
        data: dataConversationList,
        error: errorConversationList,
        refetch: refetchConversationList,
    } = useFetchConversationList({
        userId: user.id,
        withUnreadMessagesCount: true,
    }, {
        enabled: !!user,
        staleTime: 0,
    })

    const { data: dataFriends } = useFetchFriends({}, {
        enabled: !!dataConversationList && !dataConversationList.length,
    })

    const {
        getChatUserId,
        getReadConversationProps,
        getUnReadConversationProps,
        findConversationChat,
        findConversationChannel,
    } = useMessenger()

    const { data: dataChatUsersData } = useMessengerChatUsersData(chatUserIds)

    const { invalidate: invalidateConversationCountsNew } = useInvalidateConversationCountsNew()

    const handlerMessage = (value: TEventMessage, conversationList?: (IMessengerChat | IMessengerChannel)[]) => {
        filterConversationMessage(value, conversationList)
    }

    const handlerSearch = (value: string) => {
        setSearchText(value)
    }

    const handlerClickNewChat = () => {
        history.push(APP_URL.messengerChatNew)
    }

    const handlerMarkReadChat = (id: number) => {
        setConversationStatusParams(getReadConversationProps({ toUserId: id }))
    }

    const handlerMarkUnReadChat = (id: number) => {
        setConversationStatusParams(getUnReadConversationProps({ toUserId: id }))
    }

    const handlerMarkReadChannel = (id: string) => {
        // FIXME in KW-184 KW-185
        return
        // eslint-disable-next-line
        setConversationStatusParams(getReadConversationProps({ channelId: id }))
    }

    const handlerMarkUnReadChannel = (id: string) => {
        // FIXME in KW-184 KW-185
        return
        // eslint-disable-next-line
        setConversationStatusParams(getUnReadConversationProps({ channelId: id }))
    }

    function filterConversationMessage(
        { messageType, payload }: TEventMessage,
        conversationList?: (IMessengerChat | IMessengerChannel)[],
    ) {
        let isCurrentUserConversationMessage = false

        switch (messageType) {
            case MessengerMessageType.message: {
                if (payload.channelId) { // channel message
                    isCurrentUserConversationMessage = !!findConversationChannel(payload.channelId, conversationList)
                }
                if (payload.receiverUserId) { // chat message
                    isCurrentUserConversationMessage = !!findConversationChat(payload.receiverUserId, conversationList)
                }
                break
            }
            case MessengerMessageType.status: {
                if (payload.status === MessengerMessageStatus.delivered) {
                    if ('channelId' in payload) { // channel message
                        isCurrentUserConversationMessage = !!findConversationChannel(
                            payload.channelId,
                            conversationList,
                        )
                    }
                    if ('receiverUserId' in payload) { // chat message
                        isCurrentUserConversationMessage = !!findConversationChat(
                            payload.receiverUserId,
                            conversationList,
                        )
                    }
                }
                break
            }
            default:
                //
        }

        if (isCurrentUserConversationMessage) {
            debouncedInvalidate()
        }
    }

    function invalidateAction() {
        refetchConversationList()
        invalidateConversationCountsNew()
    }

    useEffect(() => {
        if (dataConversationList) {
            setChatUserIds(dataConversationList.reduce<number[]>((acc, item) => {
                return 'channelId' in item ? acc : [...acc, getChatUserId(user.id, item)]
            }, []))
        }
    }, [dataConversationList])

    return (
        <ContentContainer size="half">
            <Block classes={style.block}>
                {!isLoadingConversationList && !!dataConversationList?.length && (
                    <MessengerSearch
                        classes={style.search}
                        isDisabled={!dataConversationList}
                        onSearch={handlerSearch}
                    >
                        <MessengerMenu />
                    </MessengerSearch>
                )}

                {isLoadingConversationList && (
                    <Loader />
                )}

                {!isLoadingConversationList && errorConversationList?.length && (
                    <ErrorMsg error={errorConversationList[0]} />
                )}

                {!isLoadingConversationList && dataConversationList && !dataConversationList.length && (
                    <>
                        <NoDataInfo
                            isShowImg
                            classesText={style.noDataText}
                            text={t('chat_no_messages')}
                        />
                        {!!dataFriends?.total && (
                            <Button
                                classes={style.actionChat}
                                size="size46"
                                onClick={handlerClickNewChat}
                            >
                                {t('start_chat')}
                            </Button>
                        )}
                    </>
                )}
                {searchText && (
                    <MessengerSearchList
                        user={user}
                        searchText={searchText}
                    />
                )}
                {dataConversationList && !searchText && (
                    <div className={style.list}>
                        {dataConversationList.map((item) => (
                            <React.Fragment key={item.id}>
                                {'channelId' in item ? (
                                    <MessengerConversationChannel
                                        classes={style.listItem}
                                        data={item}
                                        user={user}
                                        onMarkRead={handlerMarkReadChannel}
                                        onUnMarkRead={handlerMarkUnReadChannel}
                                    />
                                ) : (
                                    <MessengerConversationChat
                                        classes={style.listItem}
                                        data={item}
                                        user={user}
                                        chatUserData={dataChatUsersData[item.starterUserId === user.id
                                            ? item.followerUserId
                                            : item.starterUserId]}
                                        onMarkRead={handlerMarkReadChat}
                                        onUnMarkRead={handlerMarkUnReadChat}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </Block>

            <MessageConversationAction
                message={conversationStatusParams}
                onMessage={(e) => handlerMessage(e, dataConversationList)}
            />
        </ContentContainer>
    )
}

export default MessengerConversationList
