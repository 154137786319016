import React, { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { APP_URL, STORE_TYPE_CPA_NO_GOODS } from 'config/app'
import { useFetchStoreProfile, useFetchStoreCategories } from 'containers/Store/hooks'
import { useFetchBasket } from 'containers/Market/hooks'
import { PageTitle, StoreBreadcrumbs, StoreBannerGift } from 'components'
import { scrollTop, parseTpl } from 'utils/helpers'
import { Catalog } from '../index'
import style from './StoreCatalog.module.css'

const StoreCatalog: React.FC = () => {
    const { id, catalogId } = useParams<{ id: string, catalogId?: string }>()
    const history = useHistory()

    const storeId = useMemo(() => Number(id), [id])

    const { data: dataStoreProfile } = useFetchStoreProfile({ id: storeId }, { enabled: !Number.isNaN(storeId) })

    const { data: dataStoreCategories } = useFetchStoreCategories({ storeId }, { enabled: !!dataStoreProfile })

    const { data: dataBasket } = useFetchBasket({ storeId }, { enabled: !!dataStoreProfile })

    const handlerEmptyCatalog = () => {
        goToProfile()
    }

    function goToProfile() {
        history.push(parseTpl(APP_URL.storeProfile, { ':id': storeId }, { prefix: '', suffix: '' }))
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [catalogId])

    useEffect(() => {
        if (dataStoreProfile?.gateway_type === STORE_TYPE_CPA_NO_GOODS) {
            goToProfile()
        }
    }, [dataStoreProfile])

    return (
        <div className={style.catalog}>
            {!!catalogId && (
                <PageTitle classes={style.breadcrumbs}>
                    <StoreBreadcrumbs
                        storeId={storeId}
                        catalogId={Number(catalogId)}
                        categories={dataStoreCategories}
                    />
                </PageTitle>
            )}

            <StoreBannerGift basket={dataBasket} />

            <Catalog
                isSetItemLink
                storeId={storeId}
                catalogId={catalogId ? Number(catalogId) : undefined}
                onEmptyCatalog={handlerEmptyCatalog}
            />
        </div>
    )
}

export default StoreCatalog
