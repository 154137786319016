import React, { useState, useMemo } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { IMenuNavItemData } from 'interfaces'
import { MenuNavChild, FeedTabsId } from 'enums'
import { APP_URL, COUNTERS } from 'config/app'
import * as userSelectors from 'containers/User/user-selectors'
import { useFetchBudget } from 'containers/User/hooks'
import { UserService } from 'services'
import { numberFormat } from 'utils/helpers'
import {
    MenuNavItem,
    MenuNavLink,
    MenuNavMore,
    MenuNavDropDown,
} from './components'
import style from './MenuNav.module.css'

type MenuNavPropType = {
    isMobile?: boolean
}

enum MenuKey {
    feed = 1,
    friends = 2,
    market = 3,
    reports = 4,
    wallet = 5,
    calendar = 6,
    rank = 8,
}

enum FeedChildrenKey {
    all = 'all',
    posts = 'posts',
    company = 'company',
    leaders = 'leaders',
    president = 'president'
}

const MENU_ITEMS: Record<string, IMenuNavItemData> = {
    [MenuKey.feed]: {
        id: MenuKey.feed,
        translateKey: 'feed',
        icon: 'ic_tab_feed_svg',
        url: APP_URL.lenta,
        countEvent: {
            key: COUNTERS.feedPosts,
            value: 0,
        },
        children: {
            [FeedChildrenKey.all]: {
                id: FeedTabsId.allPosts,
                url: APP_URL.lenta,
                translateKey: 'feed_all_articles',
            },
            [FeedChildrenKey.posts]: {
                id: 100,
                url: APP_URL.lentaUser,
                translateKey: 'user_profile_posts_and_checks',
            },
            [FeedChildrenKey.company]: {
                id: FeedTabsId.companyPosts,
                url: `${APP_URL.lenta}?tabId=${FeedTabsId.companyPosts}`,
                translateKey: 'company_post',
            },
            [FeedChildrenKey.president]: {
                id: FeedTabsId.presidentPosts,
                url: `${APP_URL.lenta}?tabId=${FeedTabsId.presidentPosts}`,
                translateKey: 'president_posts',
            },
        },
        childType: MenuNavChild.byQueryParams,
    },
    [MenuKey.friends]: {
        id: MenuKey.friends,
        translateKey: 'friends',
        icon: 'ic_tab_friends_svg',
        url: APP_URL.friends,
        countEvent: {
            key: COUNTERS.friendRequests,
            value: 0,
        },
    },
    [MenuKey.market]: {
        id: MenuKey.market,
        translateKey: 'stores',
        icon: 'ic_tab_market_svg',
        url: APP_URL.market,
    },
    [MenuKey.reports]: {
        id: MenuKey.reports,
        translateKey: 'reports',
        icon: 'ic_tab_report_svg',
        iconClassName: style.iconReports,
        url: APP_URL.reports,
    },
    [MenuKey.wallet]: {
        id: MenuKey.wallet,
        translateKey: 'icon_menu_wallet',
        icon: 'ic_tab_wallet_svg',
        url: APP_URL.wallet,
        countEvent: {
            key: COUNTERS.wallet,
            value: 0,
        },
    },
    [MenuKey.calendar]: {
        id: MenuKey.calendar,
        translateKey: 'calendar',
        icon: 'wallet_icon_calendar_svg',
        url: APP_URL.calendar,
        countValue: {
            key: COUNTERS.calendar,
            value: 0,
        },
    },
    [MenuKey.rank]: {
        id: MenuKey.rank,
        translateKey: 'business_menu_kicks_icon_title',
        icon: 'sessia_balance2_svg',
        // iconClassName: ,
        // url: '',
        countValue: {
            key: String(MenuKey.rank),
            value: 0,
        },
        disabled: true,
    },
}

const MENU_ITEMS_MOBILE_IDS = [MenuKey.feed, MenuKey.market]
const ITEMS_COUNT_SHOW_DESKTOP = 9
const ITEMS = Object.values(MENU_ITEMS)

const getMenuItems = (isSetCompanyAccountId5: boolean, ext?: Record<keyof typeof MENU_ITEMS, any>)
    : IMenuNavItemData[] => {
    return ITEMS.reduce<IMenuNavItemData[]>((acc, item) => {
        if (!isSetCompanyAccountId5) {
            if (item.id === MenuKey.feed && item.children) {
                const { [FeedChildrenKey.all]: all, [FeedChildrenKey.posts]: posts } = item.children
                return [...acc, { ...item, children: { all, posts } }]
            }
        }

        return [...acc, item]
    }, [])
}

const getMenuItemsMobile = (items: IMenuNavItemData[]) => {
    return items.reduce<IMenuNavItemData[]>((acc, item) => {
        return MENU_ITEMS_MOBILE_IDS.includes(item.id) ? [...acc, item] : acc
    }, [])
}

const getItemId = (pathname: string): number => {
    const currentItem = ITEMS.find(({ url }) => {
        return url ? !!matchPath(pathname, { path: url, exact: false }) : undefined
    })
    return currentItem ? currentItem.id : 0
}

const MenuNav: React.FC<MenuNavPropType> = ({ isMobile = false }) => {
    const { pathname } = useLocation()

    const user = useSelector(userSelectors.user)
    const counts = useSelector(userSelectors.counts)

    const [menuItems] = useState(getMenuItems(!!UserService.getCompanyAccountId5(user)))
    const [currentItemId, setCurrentItemId] = useState<number>(getItemId(pathname))
    const [isShowAllList, setIsShowAllList] = useState(true)

    const { data: dataBudget } = useFetchBudget()

    const menuMobileItems = useMemo(() => getMenuItemsMobile(menuItems), [menuItems])

    const menuList = useMemo(() => {
        const list = getMenuList()

        return list.map((item) => {
            const navItem = { ...item }

            if (navItem.countValue?.key && navItem.countValue.key in counts) {
                navItem.countValue.value = counts[navItem.countValue.key]
            }
            if (navItem.countEvent?.key && navItem.countEvent.key in counts) {
                navItem.countEvent.value = counts[navItem.countEvent.key]
            }
            if (navItem.countValue?.key === String(MenuKey.rank) && dataBudget !== undefined) {
                navItem.countValue.value = numberFormat(dataBudget)
            }

            return navItem
        })
    }, [menuItems, counts, dataBudget])

    const menuDropDownItems = useMemo(() => menuItems.filter((listItem) => {
        return !menuMobileItems.find((mobileItem) => (
            mobileItem.id === listItem.id && mobileItem.id !== MenuKey.feed
        ))
    }), [menuList])

    const handlerClickShowMore = () => {
        setIsShowAllList((prevState) => !prevState)
    }

    const handlerChangeSubMenu = (itemId: number) => {
        setCurrentItemId((prevState) => (prevState === itemId ? 0 : itemId))
    }

    function getMenuList() {
        if (isMobile) {
            return menuMobileItems
        }

        return menuItems
        // if (isShowAllList) {
        //     return menuItems
        // }
        //
        // return ITEMS_COUNT_SHOW_DESKTOP < menuItems.length ? menuItems.slice(0, ITEMS_COUNT_SHOW_DESKTOP) : menuItems
    }

    // function getIsShowAllList() {
    //     const menuItemIndex = menuItems.findIndex((item) => item.url === pathname)
    //
    //     return menuItemIndex ? menuItemIndex + 1 > ITEMS_COUNT_SHOW_DESKTOP : false
    // }

    return (
        <ul className={cn(style.menuNav, { [style.menuNav_mobile]: isMobile })}>
            {menuList.map((item) => (
                <MenuNavItem key={item.id}>
                    <MenuNavLink
                        isMobile={isMobile}
                        isOpen={item.id === currentItemId}
                        data={item}
                        onChangeSubMenu={handlerChangeSubMenu}
                    />
                </MenuNavItem>
            ))}

            {!isMobile && ITEMS_COUNT_SHOW_DESKTOP < menuItems.length && !isShowAllList && (
                <MenuNavItem>
                    <MenuNavMore onClick={handlerClickShowMore} />
                </MenuNavItem>
            )}

            {isMobile && !!menuDropDownItems.length && (
                <MenuNavItem>
                    <MenuNavDropDown
                        items={menuDropDownItems}
                        currentItemId={currentItemId}
                        onChangeSubMenu={handlerChangeSubMenu}
                    />
                </MenuNavItem>
            )}
        </ul>
    )
}

export default MenuNav
