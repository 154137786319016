import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { QRCodeSVG } from 'qrcode.react'

import { IRootState } from 'interfaces'
import { APP_URL } from 'config/app'
import {
    Avatar,
    SvgResource,
    Button,
    Modal,
} from 'components'
import { copyTextClipboard, showAlertNotify } from 'utils/helpers'
import style from './UserInfo.module.css'

const UserInfo: React.FC = () => {
    const { t } = useTranslation()

    const [isShowModal, setIsShowModal] = useState<boolean>(false)
    const user = useSelector((state: IRootState) => state.user)

    const handlerClickMemberId = () => {
        copyTextClipboard(user.member_id)
            .then(() => {
                showAlertNotify({ type: 'success', message: t('Copied to clipboard') })
            })
            .catch(() => {
                showAlertNotify({ type: 'error', message: t('update_error') })
            })
    }

    const handleOpenModal = () => {
        setIsShowModal(true)
    }

    const handleCloseModal = () => {
        setIsShowModal(false)
    }

    return (
        <>
            <div className={style.wrap}>
                <NavLink
                    className={style.linkEdit}
                    activeClassName={style.linkEdit_active}
                    title={t('edit')}
                    to={APP_URL.userSettings}
                >
                    <SvgResource
                        resourceKey="edit_profile_svg"
                        width={30}
                        height={30}
                    />
                </NavLink>

                <Avatar
                    src={user?.photo}
                    width={117}
                    height={117}
                    name={user?.name}
                    surname={user?.surname}
                />

                <div className={style.user}>
                    <div className={style.userName}>
                        {`${user?.name ?? ''} ${user?.surname ?? ''}`}
                    </div>
                </div>

                {user?.profileStatus && (
                    <div className={style.accountStatus}>
                        {user.profileStatus}
                    </div>
                )}

                <div className={style.data}>
                    {user?.member_id && (
                        <div className={style.dataItem}>
                            <Button
                                classes={style.dataText}
                                styleType="transparent"
                                title={t('contract_number')}
                                onClick={handlerClickMemberId}
                            >
                                {user.member_id}
                            </Button>
                        </div>
                    )}

                    {user?.member_id && (
                        <div className={style.dataItem}>
                            <span className={style.dataIcon}>
                                <QRCodeSVG
                                    value={user.member_id}
                                    bgColor="#000000"
                                    fgColor="#FFFFFF"
                                    level="L"
                                    size={32}
                                    onClick={handleOpenModal}
                                    includeMargin={false}
                                    className={style.qrCodsSVG}
                                />
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <Modal
                isOpen={isShowModal}
                size="medium"
                classes=""
                onClose={handleCloseModal}
            >
                <Modal.Header title={t('help_context_qr_title')} />
                <Modal.Body>
                    <div className={style.qr}>
                        <QRCodeSVG
                            value={user?.member_id}
                            size={400}
                            bgColor="#ffffff"
                            fgColor="#000000"
                            level="L"
                            includeMargin={false}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserInfo
