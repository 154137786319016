import {
    ICatalogItem,
    IStoreProfile,
    IStoreHeader,
    IStoreFolder,
    IStoreGoods,
    IStoreRank,
    IStoreSettings,
    IGoods,
} from 'interfaces'
import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'
import { parseTpl } from 'utils/helpers'

export type TStoreServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type TStoreCatalog = (IStoreHeader | IStoreFolder | IStoreGoods)[]

export type TStoreProfileProps = {
    id: number
}

export type TStoreCategoriesProps = {
    storeId: number
}

export type TStoreRootCatalogProps = {
    storeId: number
}

export type TStoreCatalogProps = {
    storeId: number
    catalogId: number
}

export type TStorePopularGoodsProps = {
    storeId: number
}

export type TStoreCpaLinkProps = {
    storeId: number
}

export type TStoresResponse = IStoreProfile[]

export type TStoreCatalogResponse = (IStoreHeader | IStoreFolder | IStoreGoods)[]

export type TStoreCatalogTreeResponse = ICatalogItem[]

export type TStoreProfileResponse = IStoreProfile

export type TStoreCpaLinkResponse = {
    kick_loyalty_settings: string
    kick_loyalty_settings_user: string
    kick_max_loyalty_settings: string
    kick_max_loyalty_settings_user: string
    rank: IStoreRank
    link: string
}

export type TStoreSettingsResponse = IStoreSettings[]

export type TStoreSettingsUpdateResponse = IStoreSettings[]

export type TStoreSettingsProps = {
    limit?: number
    offset?: number
    q?: string
    store_ids?: number[]
}

export type TStoreSettingsUpdateProps = {
    limit?: number
    offset?: number
    subscribe_list?: {
        id: number
        is_subscribe: boolean
    }[]
}

class StoreService {
    static fetchStoreProfile({ id }: TStoreProfileProps) {
        const url = parseTpl(API_URL.storeProfile, { store_id: id })
        return requestClient<IStoreProfile>(url)
    }

    static fetchStoreCategories({ storeId }: TStoreCategoriesProps) {
        const url = parseTpl(API_URL.catalog, { store_id: storeId })
        return requestClient<ICatalogItem[]>(url)
    }

    static fetchStoreRootCatalog({ storeId }: TStoreRootCatalogProps) {
        const url = parseTpl(API_URL.catalog, { store_id: storeId })
        return requestClient<TStoreCatalog>(url, { params: { view: 'extra-plain' } })
    }

    static fetchStoreCatalog({ storeId, catalogId }: TStoreCatalogProps) {
        const url = parseTpl(API_URL.catalog, { store_id: storeId })
        return requestClient<TStoreCatalog>(url, { params: { view: 'extra-plain', node: catalogId } })
    }

    static fetchStorePopularGoods({ storeId }: TStorePopularGoodsProps) {
        const url = parseTpl(API_URL.popularGoods, { store_id: storeId })
        return requestClient<IGoods[]>(url)
    }

    static fetchCpaStoreLink({ storeId }: TStoreCpaLinkProps) {
        const url = parseTpl(API_URL.cpaLink, { store_id: storeId })
        return requestClient<TStoreCpaLinkResponse>(url)
    }

    static fetchStoreSettings(params: TStoreSettingsProps) {
        return requestClient<TStoreSettingsResponse>(API_URL.storeSettings, { params })
    }

    static updateStoreSettings(params: TStoreSettingsUpdateProps) {
        return requestClient<TStoreSettingsUpdateResponse>(API_URL.storeSettings, { method: 'post', data: params })
    }
}

export default StoreService
